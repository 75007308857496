
  .anunt-item:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .anunt-item-title {
    font-size: 24px;
    font-weight: bold;
    color: #e60000;
    margin-bottom: 10px;
  }
  
  .anunt-item-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    border-top: 1px solid #ffffff;
  }
  
  .anunt-item-description {
    font-size: 18px;
    line-height: 1.5;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .anunt-item-date {
    font-size: 14px;
    color: #999999;
  }
  
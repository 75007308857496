.dragodana-container {
  margin-right: 0px !important;
  width: auto;
  padding-left: 16%;
  padding-right: 16%;
  padding-top: 5vh !important;
  text-align: justify;
  padding-bottom: 2vh !important;
  background-color: rgba(0, 0, 0, 0.05);
}

.p {
  color: black;
  font-size: 18px;
  text-transform: none;
}

.sate {
  list-style-type: disc;
  margin-bottom: 0px !important;
  padding-left: 20px;
  padding-bottom: 1.6vh !important;
}

.sate li {
  color: #131A22;
  font-size: 18px;
  text-transform: none;
  margin-left: 2.4vw;
}

table {
  margin-left: 4%  !important;
  border-collapse: collapse;
  display:inline-block;
  text-align: left;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

}
.paddergraph{
  padding-left: 1vw !important;
  padding-right: 1vw !important;
  display:inline-block;

}

tr{
  padding-left: 1.6vw !important;
}

th, td {
  padding: 8px;
}

th {
  font-weight: bold;
}

.color-box {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 4px;
  border-radius: 2px;
}

.red {
  background-color: red;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3);
  border-radius:  2px;
}

.blue {
  background-color: blue;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3);
  border-radius:  2px;
}

.yellow {
  background-color: yellow;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3);
  border-radius: 5%;
}

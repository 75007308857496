*, *::before, *::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: hidden;
}

html body {
  padding: 0;
  margin: 0;
  overflow-y: inherit;
}

.App {
  height: 100vh;
  width: 100vw;
  max-width: 900px;
  padding: 1rem;
  background-color: lightpink;
}
.anunt-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.anunt-item,
.anunt-item-title,
.anunt-item-description,
.anunt-item-date {
  padding-left: 0.7vw;
  padding-right: 0.7vw;
  text-align: left;
}

.dropdown-select {
  margin-top: 2.4vh;
  margin-bottom: 2.4vh;
  padding: 10px 15px;
  font-size: 18px;
  border-radius: 10px;
  border: 2px solid  #131A22;
  background-color: white;
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
}

.anunt-item-title,
.anunt-item-description,
.anunt-item-date {
  color: #000000 !important;
}

.anunt-item-title {
  font-size: 24px;
  font-weight: bold;
  color: #e60000;
  margin-bottom: 10px;
}

.anunt-details{
  color: black !important;
}

.anunt-item-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  border-top: 1px solid #ffffff;
}

.anunt-item-description {
  font-size: 18px;
  line-height: 1.5;
  color: #333333;
  margin-bottom: 10px;
}

.anunt-item-date {
  font-size: 14px;
  color: white;
}

/* ... restul codului CSS ... */

.anunt-pdf {
  background-color: #e60000;
  color: white;
  padding: 5px 10px;
  margin: 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.anunt-pdf:hover {
  background-color: #b30000;
}

.anunt-item {
    padding-top: 1%;
    cursor: pointer;
    border-bottom:2px solid rgba(35, 47, 62, 0.2) ;
  }

  .container-2{
    margin-left: 13% !important;
    margin-right: 13% !important;
    padding-top: 3.6vh !important;
    padding-bottom: 3.6vh !important;
  }
  
  helper{
    background-color: white !important;
  }

  .full-width-container {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  .anunt-item:hover {
    box-shadow: 0 8px 16px rgba(35, 47, 62, 0.2);
  }
  
  .anunt-item-title {
    font-size: 24px;
    font-weight: bold;
    color: #e60000;
    margin-bottom: 10px;
  }
  
  .anunt-item-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    border-top: 1px solid #ffffff;
  }
  
  .anunt-item-description {
    font-size: 18px;
    line-height: 1.5;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .anunt-item-date {
    font-size: 14px;
    color: #999999;
  }
  
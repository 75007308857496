.banner {
  width: 66%;
  position: relative;
  background-image: url('../images/banner.jpg'); /* Set your background image */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.icon-banner{
  width: 33%;
  display: flex;
  height: auto;
}
.column-icon{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 2hv !important;
  margin-left: 2vw;
  width: 40%;
}

.icon-text-container a {
  text-decoration: none;
  color: inherit;
}

.banner-home{
  margin-top: 6.8vh;
  opacity: 0.8;
  background-size: cover;
  min-height: 30vh;
  display: flex;
  flex-wrap: wrap;
}

.text-container{
  padding-top: 5%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

}
.text-container > h3 {
  font-size: 70% !important;
}
.text-container > p {
  color: black;
  text-transform: initial;
  font-size: 80%;
} 
.icon-text-container {
  display: flex;
  align-items: center;
  margin-top: 1.8vh;
  margin-left: 1.6vw;
  margin-right: 2vw;
}

.icon-container {
  padding: 10px;
  font-size: 4.4vh;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-right: 10px;
}



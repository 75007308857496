.footer-container {
    background: linear-gradient(to bottom, #131A22 70%, #37475A 100%);
    color: #fff;
    padding: 50px 0;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .footer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .contact-list{
    list-style-type: disc;
  }
  .facebook-square {
  font-size: 1.4em; /* Mărește dimensiunea iconiței */
  border-radius: 4px; /* Rotunjește colțurile pătratului */
  padding: 10px; /* Adaugă spațiu în interiorul pătratului */
  border: 2px solid white;
  color: white; /* Setează culoarea iconiței */
  width: 1.5vw !important;
  height: 1.5vw !important;
}

/* Adaugă stil pentru starea hover a link-ului */
a:hover .facebook-square {
  background-color: #2d4473;
}
  .footer-col {
    flex-basis: 50%;
    margin-bottom: 30px;
    padding-left: 9vw !important;
  }
  
  .footer-col h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .footer-col p {
    margin-bottom: 20px;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .social-icons li {
    display: inline-block;
    margin-right: 10px;
  }
  
  .social-icons a {
    color: #fff;
  }
  
  li {
    padding-top: 1.2vh !important;
  }

  .social-icons i {
    font-size: 24px;
  }
  
  .col-sm {
    text-align: center;
    margin-top: 20px;
  }
  
  .col-sm a {
    color: #fff;
    text-decoration: none;
  }
  
  .col-sm a:hover {
    text-decoration: underline;
  }
  
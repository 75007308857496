.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 20%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    max-width: 60%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 30vw;
   
  }
  
  .modal-image img {
    object-fit: contain;
    max-height: 80vh;
    max-width: 40vw;
  }

  .modal-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
  }

  button{
    background: linear-gradient(to bottom, #131A22 80%, #37475A 120%);
    color: white;
    font-size: 1.6vh !important;
  }
  
  .close-button {
    position: absolute;
    top: 1.4vh;
    right: 1.4vh;
    background-color: transparent;
    border: none;
    font-size: 1.6vh;
    cursor: pointer;
  }
.mobile-navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 5vh;
    background: linear-gradient(to bottom, #131A22 70%, #37475A 100%);
    display: flex;
    justify-content: flex-end;
  }

  .logo-container-mobile {
    padding-right: 78vw; /* Add space to the left of the logo */
    padding-top: 0.6vh;
  }
  
  .logo-mobile {
    height: 30px; /* Adjust the size of the logo as needed */
  }
  
  .burger-menu {
    border-radius: 12%;
    margin-right: 5%;
    margin-top: 2.4%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: transparent;
    height: 24px;
  }
  
  .bar {
    background: white;
    height: 12%;
    width: 30px;
  }

  .menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    background: linear-gradient(to bottom, #131A22 100%, #37475A 100%);
    overflow-y: auto;
    padding: 15px;
  }
  
  .menu-item {
    padding: 10px 0;
    cursor: pointer;
    color: white;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    padding-left: 2vw;
    margin-top: 2vh;
    border-left: 3px solid #FFEBEE; /* Separate each item */
  }
  
  .menu-item:last-child {
    border-bottom: none; /* Remove border from the last item */
  }
  
  .submenu {
    padding: 5px 0 5px 20px; /* Indentation for submenu */
    color: white; /* Text color for submenu */
  }

  .submenu a {
    margin-top: 1vh;
    display: block;
    padding: 5px 0; /* Add some vertical padding for spacing between links */
  }
  
  .menu-item a {
    text-decoration: none; /* This will remove the underline */
    color: inherit; /* This will keep the text color consistent with the .menu-item class */
  }

  .back-button {
    position: absolute;
    top: 50%; /* Adjust this value to center the button vertically */
    transform: translateY(-50%); /* Adjust this value to center the button vertically */
    left: 16px; /* Distance from the left edge of the viewport */
    color: white; /* Color of the back arrow/text */
    font-size: 1em; /* Size of the text */
    cursor: pointer; /* Changes the mouse cursor on hover */
    padding: 10px; /* Space around the text */
    z-index: 1010; /* Ensures the button is above other elements */
  }
  
  @media (max-width: 767px) {
    .mobile-navbar {
      display: flex;
    }
  }
  
  @media (min-width: 768px) {
    .mobile-navbar {
      display: none;
    }
  }
  
.custom-card {
    position: relative;
    border: none;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  
  .custom-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3);
  }
  
  .card-image {
    height: 200px;
    object-fit: cover;
    filter: brightness(1);
  }
  
  .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .card-body {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2;
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .card-description {
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .card-button {
    border-radius: 20px;
    font-size: 1.6vh !important;
    font-weight: bold;
    padding: 10px 30px;
    border-color: #fff;
    transition: all 0.3s ease-in-out;
  }
  
  .card-button:hover {
    background-color: #fff;
    color: #f44336;
  }
  
.container-om {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    align-items: center;
    padding-top: 2.6vh !important;
  }
  
  .imagine {
    flex: 0 0 30%;
    margin-right: 30px;
  }
  
  .text > p {
    color: black;
  }

  .text-lista-om{
    font-size: 1.9vh !important;
  }
  
  .imagine img {
    width: 24vmax;
    height: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  
  .text {
    flex: 1 1 auto;
  }
  
  h2 {
    margin-top: 0;
    font-size: 24px;
  }
  
  p {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.4;
  }
  .center-content {
    text-align: center;
  }
  
  .atributii2{
    padding-top: 1.8vh !important;
    font-size: 3.4vh !important;
  }

  .nume{
    padding-top: 1.6vh !important;
    font-size: 5.0vh !important;
  }
  .lista-atributii{
    font-size: 2.4vh !important;
    list-style-type: disc;
    text-align: left;
    display: inline-block;
    padding-left: 1rem;
  }
.depart-line {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: linear-gradient(to bottom, #131A22 80%, #37475A 120%);
  opacity: 1;
}

p{
  font-family: 'Open Sans', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  color: white;
}

.line {
  width: 100%;
}

.text {
  text-align: center;
  font-size: 36px;
  margin: 0;
  padding: 0;
}
.depart-line{
  height: auto;
}
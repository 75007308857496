.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24vw;
  }
  
  .grid-item {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 100%;
  }
  
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
  }
  
  .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .date {
    font-size: 1rem;
    color: #777;
  }
  
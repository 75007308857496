.anunturi-banner-container {
    width: 100%;
    max-width: 100vw;
    height: 100% !important;
  }
  
  .anunturi-banner-container, .anunturi-banner, .slick-slide img {
    box-sizing: border-box !important;
}

 .full-width {
    width: 100%;
    margin: 0;
  }

  .hero-image {
    width: 100%;
    height: 100% !important;
  }
  
    
  
.anunturi-banner-line {
    border-top: 2vh solid #124376;
    opacity: 0.9 !important;
  }
  
.slick-dots {
    bottom: 10px;
  }
  
.slick-dots li button:before {
    font-size: 14px;
    color: #ffffff;
    opacity: 0.8;
  }
  .slick-slide img {
    -webkit-transition: all 0.00001s linear ;
    transition: all 0.0001s linear ;
    max-height: 37vh;
    object-fit: cover;
    opacity: 0.8 !important;
  }


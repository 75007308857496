/* Stilizare container principal */
.container-fluid {
    background-color: #ffffff;
    padding: 0;
    margin: 0;
  }
  

  
  .banner h1 {
    font-size: 48px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 1px 1px #333333;
  }
  
  .banner p {
    font-size: 24px;
    color: #ffffff;
    text-shadow: 1px 1px #333333;
  }
  
  /* Stilizare carduri */
  .card {
    margin-top: 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #cccccc;
    transition: box-shadow 0.2s ease-in-out;
  }
  
  .card:hover {
    box-shadow: 0px 0px 20px #cccccc;
  }
  
  .card-img-top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .card-text {
    font-size: 18px;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .card-link {
    color: #dc3545;
    font-weight: bold;
    text-decoration: none;
  }
  
  .card-link:hover {
    text-decoration: none;
    color: #c82333;
  }
  
  /* Stilizare newsletter */
  .newsletter {
    background-color: #f8f9fa;
    padding: 50px;
    margin-top: 50px;
  }
  .full-width-container {
    width: 100%;
    max-width: none;
    background-color: white;
    opacity: 0.8;
  }
  
  .newsletter h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .newsletter p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .newsletter form {
    display: flex;
  }
  
  .newsletter input[type="email"] {
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 70%;
    margin-right: 10px;
  }
  
  .newsletter button {
    background-color: #dc3545;
    border: none;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .newsletter button:hover {
    background-color: #c82333;
  }
  
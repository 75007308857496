.ceva {
  background: linear-gradient(to bottom, #131A22 70%, #37475A 100%);
  padding: 0px !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; /* To ensure the navbar stays on top of other elements */
}

.sticky-navbar {
  position: sticky;
  top: 0;
  z-index: 100; /* Pentru a ne asigura că bara de navigare rămâne deasupra celorlalte elemente */
}

.dropdown-menu {
    opacity: 0.9  !important;
    background: linear-gradient(to bottom, #131A22 70%, #37475A 100%);
    width: 46vw !important;
    margin-top: 0 !important;
    border-top-width: 0px !important;
}
  
.dropdown-menu-right {
    
    right: 0 !important;
    left: auto !important;
}

    
.logo {
width: 40px;
height: auto;
margin-right: 25px;
margin-left: 25px;
}
.logo-item > a{ 
    margin-top: 20px !important;
    font-size: 14px !important;  
    margin-left: 5.6vw  !important;
    margin-right: 5.6vw  !important;
}

ul.dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    }

a{
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    color: white !important;
}

.container-fluid {
    width: 99%;
    background: linear-gradient(to bottom, #131A22 70%, #37475A 100%);
    opacity: 0.9 !important;
    padding: 20px;
  }

.navbar-nav{
    background: linear-gradient(to bottom, #131A22 70%, #37475A 100%);
    position: relative;
}

.container-fluid{
    background-color: #bebebe !important;
}

.nav-item {
    display: flex;
    align-items: center;
    font-weight: 600; /* Reduce font-weight de la 900 la 600 */
    font-size: 12px; /* Reduce font-size de la 14px la 12px */
    padding-top: 2px !important; /* Ajustează spațierea verticală pentru fiecare nav-item */
    padding-bottom: 2px !important;
   
  }
  
.nav-link {
    padding-top: 4px !important; /* Ajustează spațierea verticală pentru fiecare nav-link */
    padding-bottom: 4px !important;
    border-right: 1px solid #FFEBEE;
}

.nav-item-bis{
    border-left: 1px solid #FFEBEE;
}

.nav-item-bis-remove{
    border-right: 0px solid #FFEBEE !important;
}

/* Add this to style the navbar-toggler button */
.navbar-toggler {
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0.25rem 0.75rem;
  }
  
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: no-repeat center center;
    background-size: 100% 100%;
    border: none;
    cursor: pointer;
    filter: invert(1);
  }


  @media (min-width: 768px) {
    .navbar {
      display: flex;
    }
  }
  
  @media (max-width: 767px) {
    nav{ 
      display: none !important;
    }
  }

  


.istoric-padder{
    padding-left: 12.7vw;
    padding-right: 12.7vw;
    text-justify: auto;
    padding-top: 5.6vh;
    padding-bottom: 5.6vh;
}
.istoric-text{
    color: black !important;  
    padding-top: 1.6vh;
    padding-bottom: 1.6vh;
    text-transform: capitalize !important;
}
.sectiune-despre {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .text-frumos {
    color: black;
    font-size: 14px;
    line-height: 1.5;
    list-style-type: none;
    padding: 0;
  }
  
  .text-frumos li {
    margin-bottom: 10px;
  }
  
  /* Stilizare pentru div-ul interior-sectiune-despre */
  .interior-sectiune-despre {
    padding-top: 5.6vh;
    width: 80%;
    text-align: justify;
    color: black !important;
  }
  
  /* Stilizare pentru secțiunea gallery */
  .gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3.6vh;
    
  }

  .text-header{
    font-size: 3.4vh !important;
  }

  .text-subheader{
    font-size: 2.4vh !important;
    list-style-type: circle;
  }

  .text-list{
    font-size:2vh !important;
    list-style-type: disc;
  }
  
  .button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    background: linear-gradient(to bottom, #131A22 80%, #37475A 120%);
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: white;
  }
  
  .button:hover {
    background-color: #e0e0e0;
  }
h1{
    padding-top: 5.6vh !important;
    padding-bottom: 5.6vh !important;
}
.padder{
    padding-bottom: 5vh !important;
}
.background-color{
    background-color: white;
    opacity: 1;
}
h3{
    font-weight: 700 !important;
    font-size: 2.6vh !important;
}